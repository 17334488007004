import '../../scss/index.scss';

(function () {
  const forgotPasswordEl = document.querySelector('#forgot-password');
  const usernameEl = document.querySelector('#username');
  const moreInfoEl = document.querySelector('#more-info');

  if (forgotPasswordEl) {
    forgotPasswordEl.addEventListener('click', handleClick);
  }

  function warningMessage () {
    moreInfoEl.classList.remove('feedback');
    moreInfoEl.classList.add('warning');
  }

  function feedbackMessage () {
    moreInfoEl.classList.remove('warning');
    moreInfoEl.classList.add('feedback');
  }

  function handleClick () {
    moreInfoEl.innerHTML = '';
    feedbackMessage();

    if (!usernameEl.value) {
      warningMessage();
      moreInfoEl.innerHTML = '<p>Please fill in username and try again</p>';
      return;
    }

    const body = new FormData();
    body.append('email', usernameEl.value);

    fetch(
      'database/sendPasswordResetEmail.php',
      {
        method: 'POST',
        body
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          moreInfoEl.innerHTML = '<p>Password reset email sent.</p>';
          return;
        }

        warningMessage();
        moreInfoEl.innerHTML = `<p>Error: ${data.error} - Contact support.</p>`;
      })
      .catch((error) => {
        warningMessage();
        moreInfoEl.innerHTML = `<p>Error: ${error.status} - ${error.message}</p>`;
      });
  }
})();
